import { IBusinessActionTypes, DATA_LOADING, SURVEY_LOADED, QUESTION_LOADED, MEMBER_LOADED } from './types';

import { Loading } from './enums';
import { ISurvey, IQuestion } from './interfaces';

export const businessLoading = (loading: Loading, isLoading: boolean): IBusinessActionTypes => {
	return {
		type: DATA_LOADING,
		loading,
		isLoading,
	};
};

export const surveyLoaded = (survey: ISurvey): IBusinessActionTypes => {
	return {
		type: SURVEY_LOADED,
		survey,
	};
};

export const questionLoaded = (question?: IQuestion): IBusinessActionTypes => {
	return {
		type: QUESTION_LOADED,
		question,
	};
};

export const memberLoaded = (pin?: string, code?: string, userVoteWeight?: number): IBusinessActionTypes => {
	return { type: MEMBER_LOADED, pin, code, userVoteWeight };
};
