import React from 'react';
import { IQuestion } from '../../store/business/interfaces';
import { ToggleableIcon } from '../../shared/components/atoms/ToggleableIcon';
import { useTranslation } from 'react-i18next';

export interface Props {
	question: IQuestion;
}

export const MultiSelectIcon = (props: Props) => {
	const { t } = useTranslation();

	return (
		<ToggleableIcon
			toggled={props.question.votesPerMember > 1}
			toggledText={t('multiSelect.multiple')}
			toggledIconName='MultiSelect'
			untoggledText={t('multiSelect.simple')}
			untoggledIconName='StatusCircleCheckmark'
		/>
	);
};
