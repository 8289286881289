import React from 'react';
import { FontIcon } from 'office-ui-fabric-react';
import styles from './ToggleableIcon.module.scss';

export interface Props {
	toggled: boolean;
	untoggledIconName?: string;
	untoggledText?: string;
	toggledIconName: string;
	toggledText: string;
}

export const ToggleableIcon = (props: Props) => {
	return (
		<span className={styles.toggleableIcon}>
			{props.toggled ? (
				<>
					<FontIcon iconName={props.toggledIconName} />
					<span>{props.toggledText}</span>
				</>
			) : props.untoggledIconName && props.untoggledText ? (
				<>
					<FontIcon iconName={props.untoggledIconName} />
					<span>{props.untoggledText}</span>
				</>
			) : (
				<></>
			)}
		</span>
	);
};
