import React from 'react';
import { PrimaryButton, IButtonProps } from 'office-ui-fabric-react';
import { useLoading } from '../../shared/hooks';
import { useTranslation } from 'react-i18next';

export interface Props extends IButtonProps {
	loadingClassName?: string;
	disabled: boolean;
}

export const LoadingSpinnerButton = (props: Props) => {
	const loading = useLoading();
	const { t } = useTranslation();
	return (
		<>
			{loading ? (
				<div className={props.loadingClassName}>
					{/* <Spinner size={SpinnerSize.medium} label='Lade...' labelPosition='right' /> */}
					{t("loading.wait")}
				</div>
			) : (
				<PrimaryButton {...props}/>
			)}
		</>
	);
};
