import React, { useContext, useEffect } from 'react';
import styles from './Voting.module.scss';
import { ISurvey } from '../store/business/interfaces';
import { SignalRContext, ISignalRConnection } from '../shared/SignalRContext';
import { SecurityIcon } from '../components/atoms/SecurityIcon';
import { QuestionService } from '../services/QuestionService';
import { useSelector } from 'react-redux';
import { AppState } from '../store';
import { Text } from 'office-ui-fabric-react';
import { VotingForm } from '../components/VotingForm';
import { useTabIsVisible } from '../hooks';
import { anyInArray } from '../shared/utils';
import { VotingService } from '../services/VotingService';
import { MultiSelectIcon } from '../components/atoms/MultiSelectIcon';
// import { showError } from '../shared/notifications';
import { useTranslation } from 'react-i18next';

export interface Props {
	survey: ISurvey;
}

export const Voting = (props: Props) => {
	const connection = useContext<ISignalRConnection>(SignalRContext);
	const question = useSelector((state: AppState) => state.business.question);
	const { t } = useTranslation();

	if (!question) {
		QuestionService.loadQuestion();
	}

	useEffect(() => {
		connection.on('voting', (args) => {
			if (args.type === 'deleted') {
				VotingService.logout().then(() => {
					window.location.href = '/';
				});
			} else {
				QuestionService.loadQuestion();
			}
		});
		return () => {
			connection.off('voting');
		};
	}, [connection]);

	useTabIsVisible((visible) => {
		if (visible) {
			QuestionService.loadQuestion();
		}
	});

	return (
		<div className={styles.votingContainer}>
			{question ? (
				<div className={styles.votingContentContainer}>
					<div className={styles.vontingHeader}>
						<div className={styles.marginContainer}>
							<SecurityIcon question={question} />
							<MultiSelectIcon question={question} />
							<Text className={styles.votingQuestion}>{question.title}</Text>
						</div>
					</div>
					<VotingForm
						question={question}
						onSend={(answerOptions) => {
							if (anyInArray(answerOptions)) {
								QuestionService.answerQuestion(question, answerOptions);
							} else {
								if (question.votesPerMember === 1) {
									// showError('Sie müssen eine Antwort auswählen.');
								} else {
									// showError('Sie müssen mindestens eine Antwort auswählen.');
								}
							}
						}}
						onUnVote={() => {
							QuestionService.unAnswer(question);
						}}
						marginContainerName={styles.marginContainer}
					/>
				</div>
			) : (
				<div className={styles.votingContentContainer}>
					<div className={styles.vontingHeader}>
						<div className={styles.marginContainer}>
							<div className={styles.votingContentContainerNoVote}>{t('voting.noVoteActive')}</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};
