import { store } from '../store';
import { xhr } from '../xhr';
import { systemLoading } from '../store/system/actions';
import { SystemLoading } from '../store/system/enums';
import { surveyLoaded, businessLoading, memberLoaded } from '../store/business/actions';
// import { showError } from '../shared/notifications';
import { Loading } from '../store/business/enums';
import { SessionService } from './SessionService';

export class VotingService {
	public static async ensureSurvey() {
		const { business } = store.getState();
		if (business.loading[Loading.Survey] || business.survey) {
			return;
		}
		store.dispatch(businessLoading(Loading.Survey, true));
		try {
			const response = await xhr(`Voting/Survey`, {
				method: 'GET',
			});
			if (response.data['title']) {
				store.dispatch(surveyLoaded({ title: response.data['title'] }));
			}
		} finally {
			store.dispatch(businessLoading(Loading.Survey, false));
		}
	}

	public static async logout() {
		await SessionService.deleteSession();
		const { system } = store.getState();
		if (system.loading[SystemLoading.Login]) {
			return;
		}
		store.dispatch(systemLoading(SystemLoading.Login, true));
		try {
			await xhr(`Member/Logout`, {
				method: 'GET',
			});
		} finally {
			store.dispatch(systemLoading(SystemLoading.Login, false));
		}
	}

	public static async validateMemberCode(code: string, pin?: string) {
		const { system } = store.getState();
		if (system.loading[SystemLoading.Login]) {
			return;
		}
		store.dispatch(systemLoading(SystemLoading.Login, true));
		try {
			const response = await xhr(`Member/Code`, {
				method: 'POST',
				data: { code, pin },
				//onError: (err) => {
				//if (err.response?.status === 404) {
				// if (!pin) showError(`Die Sitzungs-ID ist unbekannt!`);
				// else showError(`Die PIN ist unbekannt!`);
				//} else {
				//showError(err);
				//}
				//return Promise.reject();
				//},
			});
			if (response.data['title']) {
				store.dispatch(surveyLoaded({ title: response.data['title'] }));
			}

			store.dispatch(memberLoaded(response.data['pin'], response.data['code'], response.data['voteWeight']));
			SessionService.createSession(response.data['pin'], response.data['code'], response.data['voteWeight']);
		} finally {
			store.dispatch(systemLoading(SystemLoading.Login, false));
		}
	}
}
