import { store } from '../store';
import { Loading } from '../store/business/enums';
import { businessLoading, questionLoaded } from '../store/business/actions';
import { xhr } from '../xhr';
import { IQuestion, IAnswerOption, IAnsweredOption } from '../store/business/interfaces';
import { createGuid, anyInArray } from '../shared/utils';
import { VotingService } from './VotingService';
// import { showMessage, showError } from '../shared/notifications';
// import { MessageType } from '../store/system/enums';

export class QuestionService {
	public static parse(data: any): IQuestion | undefined {
		if (!data) {
			return undefined;
		}
		const answerOptions = (data['answerOptions'] as []) || [];
		const votesPerMember = parseInt(data['votesPerMember']);
		const answeredOptions = (data['answeredOptions'] as []) || [];
		return {
			id: data['id'],
			title: data['title'],
			options: answerOptions.map(
				(a: any) =>
					({
						id: a['id'],
						title: a['title'],
					} as IAnswerOption)
			),
			security: data['security'],
			votesPerMember: isNaN(votesPerMember) ? -1 : votesPerMember,
			votingId: createGuid(),
			hasAnswered: !!data['hasAnswered'],
			answeredOptions: answeredOptions.map((ao: any) => ({
				id: ao['answerOptionId'],
				voteWeight: parseInt(ao['voteWeight']) ? parseInt(ao['voteWeight']) : 1,
			})),
		};
	}
	public static async loadQuestion() {
		const { business } = store.getState();
		if (business.loading[Loading.Question]) {
			return;
		}
		store.dispatch(businessLoading(Loading.Question, true));
		try {
			const response = await xhr(`Voting/Question`, {
				method: 'GET',
			});
			const question = QuestionService.parse(response.data);
			if (business.question?.id !== question?.id) {
				store.dispatch(questionLoaded(question));
			}
		} finally {
			store.dispatch(businessLoading(Loading.Question, false));
		}
	}
	public static async answerQuestion(question: IQuestion, answerOptions: IAnsweredOption[] | undefined) {
		const { business } = store.getState();
		if (business.loading[Loading.Answer]) {
			return;
		}
		store.dispatch(businessLoading(Loading.Answer, true));
		const answerOptionIdsAndWeights =
			answerOptions?.map((o) => {
				return { id: o.id, voteWeight: o.voteWeight };
			}) ?? [];
		try {
			await xhr(`Voting/Answer`, {
				method: 'POST',
				data: { answerOptions: answerOptionIdsAndWeights, votingId: question.votingId },
				onError: (err) => {
					if (err.response?.status === 401) {
						//log out user
						// showError('Zugriff verweigert! Sie werden automatisch ausgeloggt!');
						window.setTimeout(() => {
							VotingService.logout().then(() => {
								window.location.href = '/';
							});
						}, 4000);
					} else {
						// showError(err);
					}
					return Promise.reject();
				},
			});
			store.dispatch(questionLoaded({ ...question, hasAnswered: anyInArray(answerOptions), answeredOptions: answerOptionIdsAndWeights }));
			// showMessage('Ihre Abstimmung wurde erfolgreich übertragen.', MessageType.SUCCESS);
		} finally {
			store.dispatch(businessLoading(Loading.Answer, false));
		}
	}
	static unAnswer(question: IQuestion) {
		store.dispatch(questionLoaded({ ...question, hasAnswered: false }));
	}
}
