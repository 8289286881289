import React from 'react';
import { IQuestion } from '../../store/business/interfaces';
import { ToggleableIcon } from '../../shared/components/atoms/ToggleableIcon';
import { useTranslation } from 'react-i18next';

export interface Props {
	question: IQuestion;
}

export const SecurityIcon = (props: Props) => {
    const { t } = useTranslation();
	return (
		<ToggleableIcon 
			toggled={props.question.security === 'private'}
			toggledText={t('security.private')}
			toggledIconName='Lock'
			untoggledText={t('security.public')}
			untoggledIconName='Unlock'
		/>
	);
};
