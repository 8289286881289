import React, { useState, useEffect } from 'react';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react';
import styles from './Messages.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { store, AppState } from '../../../store';
import { IMessage } from '../../../store/system/interfaces';
import { MessageType } from '../../../store/system/enums';
import { messageRemove } from '../../../store/system/actions';

export const Messages = () => {
	const dispatch = useDispatch();
	const messages = useSelector((state: AppState) => state.system.messages);
	const [timer, setTimer] = useState(0);

	useEffect(() => {
		//console.log('start clean up message', timer);
		if (timer === 0) {
			setTimer(
				window.setInterval(() => {
					const past = new Date(new Date().getTime() - 10 * 1000);
					const currentMessages = store.getState().system.messages;
					const messageIndex = currentMessages.findIndex((m) => m.created < past);
					//console.log('clean up message', past, messageIndex);
					if (messageIndex > -1) {
						dispatch(messageRemove(messageIndex));
					}
				}, 5000)
			);
		}
		return () => {
			//console.log('end clean up message', timer);
			if (timer > 0) {
				window.clearInterval(timer);
				setTimer(0);
			}
		};
	}, [timer, dispatch]);

	const renderMessage = (message: IMessage, index: number) => {
		let messageBarType: MessageBarType = MessageBarType.info;
		let messageText = '';
		if (!message) {
		return;
		}
		switch (message.type) {
			case MessageType.ERROR:
				messageBarType = MessageBarType.error;
				break;
			case MessageType.SUCCESS:
				messageBarType = MessageBarType.success;
				break;
			case MessageType.WARN:
				messageBarType = MessageBarType.warning;
				break;
			default:
				break;
		}
		if (!messageText) {
			messageText = message.message || '';
		}
		if (!messageText) {
			return;
		}
		return (
			<MessageBar
				key={index}
				messageBarType={messageBarType}
				isMultiline={true}
				onClick={() => dispatch(messageRemove(index))}
				dismissButtonAriaLabel='Schließen'
				onDismiss={() => dispatch(messageRemove(index))}
			>
				{messageText}
			</MessageBar>
		);
	};

	return <div className={styles.messages}>{messages.map((m, i) => renderMessage(m, i))}</div>;
};
