import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Route, Switch, useLocation, useHistory } from 'react-router-dom';
import { LoginCode } from './LoginCode';
import { VotingService } from '../services/VotingService';
import { useSelector } from 'react-redux';
import { AppState } from '../store';
import { Voting } from './Voting';
import { SignalR } from '../shared/SignalRContext';
import styles from './Hub.module.scss';
import logo from '../images/logo.png';
import { FontIcon } from 'office-ui-fabric-react';
import { Menu } from '../components/Menu';
import { SystemService } from '../services/SystemService';
import { useTranslation } from 'react-i18next';

export const Hub = () => {
	const title = useSelector((state: AppState) => state.system.title);
	const apiBasePath = useSelector((state: AppState) => state.system.apiBasePath);
	const userId = useSelector((state: AppState) => state.system.userId);
	const survey = useSelector((state: AppState) => state.business.survey);
	const [code, setCode] = useState('');
	const history = useHistory();
	const location = useLocation();
	const [openMenu, SetOpenMenu] = useState(false);
	const [showSurveyCodeError, SetShowSurveyCodeError] = useState(false);
	const [showPinError, SetShowPinError] = useState(false);
	const transitionNodeRef = useRef<HTMLDivElement>(null);
	const { t } = useTranslation();

	const validateSurveyCode = useCallback(
		(surveyCode: string) => {
			VotingService.validateMemberCode(surveyCode)
				.then(() => {
					setCode(surveyCode);
					history.push('/pin');
				})
				.catch(() => {
					SetShowSurveyCodeError(true);
				});
		},
		[history]
	);

	useEffect(() => {
		if (!userId) {
			//try load survey code from Query String
			const params = new URLSearchParams(location.search);
			const surveyCode = params.get('survey');
			if (surveyCode) {
				validateSurveyCode(surveyCode);
				history.push('/');
			}
		} else {
			history.push('/voting');
		}
	}, [userId, history, location.search, validateSurveyCode]);

	useEffect(() => {
		if ((location.pathname === '/voting' && !userId) || (location.pathname === '/pin' && !code && !userId)) {
			history.push('/');
		}
	}, [history, location.pathname, userId, code]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		document.title = survey?.title || title;
	}, [survey, title]);

	const renderVoting = () => {
		if (apiBasePath && userId) {
			VotingService.ensureSurvey();
			if (survey) {
				return (
					<div key='votingPage' ref={transitionNodeRef}>
						<div className={styles.headerContainer}>
							<div className={styles.headerInnerContainer}>
								<h1>{survey?.title || title}</h1>
								<div
									className={styles.menuButton}
									onClick={() => {
										SetOpenMenu(true);
									}}
								>
									<FontIcon iconName='BulletedListText' />
								</div>
							</div>
						</div>
						<SignalR url={apiBasePath} userId={userId}>
							<Voting survey={survey} />
						</SignalR>
					</div>
				);
			}
		}
		return '';
	};

	const logout = () => {
		VotingService.logout().then(() => {
			window.location.href = '/';
		});
	};

	return (
		<div className={styles.pageContainer}>
			<Switch key='switch' location={location}>
				<Route key='routeVoting' path='/voting' exact={true}>
					{renderVoting()}
				</Route>
				<Route key='routePin' path='/pin' exact={true}>
					<div key='pinPage' className={[styles.pinpage].join(' ')} ref={transitionNodeRef}>
						<div className={styles.headerContainer}>
							<div className={styles.headerInnerContainer}>
								<h1>{survey?.title || title}</h1>
								<div
									className={styles.menuButton}
									onClick={() => {
										SetOpenMenu(true);
									}}
								>
									<FontIcon iconName='BulletedListText' />
								</div>
							</div>
						</div>
						<div className={styles.contentContainer}>
							<LoginCode
								key='pin'
								label={t('login.pin.label')}
								maxLength={6}
								placeholder={t('login.pin.placeholder')}
								onNext={(value) => {
									VotingService.validateMemberCode(code!, value)
										.then(() => SystemService.ensureConfig(apiBasePath, true))
										.catch(() => {
											SetShowPinError(true);
										});
								}}
								type='pin'
								validationErrorVisible={showPinError}
								validationErrorMessage={t('login.pin.errorMsg')}
							/>
						</div>
					</div>
				</Route>
				<Route key='routeRoot' path='/' exact={false}>
					<div key='startPage' className={styles.startpage} ref={transitionNodeRef}>
						<div className={styles.headerContainer}>
							<img alt={t('votr')} src={logo} />
						</div>
						<div className={styles.contentContainer}>
							<LoginCode
								key='code'
								label={t('login.code.label')}
								placeholder={t('login.code.placeholder')}
								maxLength={6}
								onNext={(value) => {
									validateSurveyCode(value);
								}}
								type='id'
								validationErrorVisible={showSurveyCodeError}
								validationErrorMessage={t('login.code.errorMsg')}
							/>
						</div>
						<div className={styles.contentExitContainer}></div>
					</div>
				</Route>
			</Switch>
			<div className={[styles.menuPage, openMenu ? styles.open : ''].join(' ')}>
				<div className={styles.headerContainer}>
					<div className={styles.headerInnerContainer}>
						<h1>{t('header.menu')}</h1>
						<div
							className={styles.menuButton}
							onClick={() => {
								SetOpenMenu(false);
							}}
						>
							<FontIcon iconName='Cancel' />
						</div>
					</div>
				</div>
				<div className={styles.contentContainer}>
					<Menu
						onToggleMenu={() => {
							SetOpenMenu(false);
							logout();
						}}
					/>
				</div>
			</div>
		</div>
	);
};
