import React, { useState } from 'react';
import { TextField } from 'office-ui-fabric-react';
import styles from './LoginCode.module.scss';
import { LoadingSpinnerButton } from '../components/atoms/LoadingSpinnerButton';
import { useTranslation } from 'react-i18next';

export interface Props {
	onNext: (code: string) => void;
	label: string;
	placeholder: string;
	type: string;
	maxLength?: number;
	validationErrorVisible?: boolean;
	validationErrorMessage: string;
}

export const LoginCode = (props: Props) => {
	const [code, SetCode] = useState('');
	const { t } = useTranslation();

	return (
		<div className={[styles.loginContainer, props.type === 'pin' ? styles.specialContainer : ''].join(' ')}>
			<TextField
				autoFocus
				label={props.label}
				placeholder={props.placeholder}
				defaultValue={code}
				maxLength={props.maxLength}
				autoComplete='off'
				onChange={(evt, value) => {
					SetCode(value ?? '');
				}}
				onKeyUp={(evt) => {
					if (evt.keyCode === 13) {
						props.onNext(code);
					}
				}}
				className={props.validationErrorVisible ? styles.error : ''}
				type={props.type === 'pin' ? 'number' : 'text'}
				errorMessage={props.validationErrorVisible ? props.validationErrorMessage : ''}
			/>
			<LoadingSpinnerButton
				loadingClassName={styles.loadingButton}
				text={t("button.continue")}
				onClick={() => {
					props.onNext(code);
				}}
				disabled={code.length === 0}
			/>
		</div>
	);
};
