import { AppState } from '../store';
import { useSelector } from 'react-redux';
import { Loading } from '../store/business/enums';
import { SystemLoading } from '../store/system/enums';

export const useLoading = (excludeSystem?: SystemLoading[], excludeBusiness?: Loading[]): boolean => {
	const systemLoadingState = useSelector((state: AppState) => state.system.loading);
	const businessLoadingState = useSelector((state: AppState) => state.business.loading);

	const systemExcludeLoading = excludeSystem ? excludeSystem : [];
	const systemLoading = Object.keys(systemLoadingState).reduce((agg, l) => {
		if (systemExcludeLoading.indexOf(l as SystemLoading) > -1) return agg;
		return systemLoadingState[l as SystemLoading] || agg;
	}, false);

	if (systemLoading) {
		//console.log('systemloading', systemLoadingState, businessLoadingState);
		return true;
	}

	const businessExcludeLoading = excludeBusiness ? excludeBusiness : [];
	const businessLoading = Object.keys(businessLoadingState).reduce((agg, l) => {
		if (businessExcludeLoading.indexOf(l as Loading) > -1) return agg;
		return businessLoadingState[l as Loading] || agg;
	}, false);
	// if (businessLoading) console.log('businessloading', systemLoadingState, businessLoadingState);
	// else console.log('no loading');
	return businessLoading;
};

export const useTimeout = (key: string, timeout: number, func: TimerHandler): void => {
	const w = window as { votrTimers?: { [key: string]: number } };
	w.votrTimers = w.votrTimers || {};
	let timer = w.votrTimers[key];
	window.clearTimeout(timer);
	timer = window.setTimeout(func, timeout);
	w.votrTimers[key] = timer;
};
