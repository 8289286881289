import React, { useEffect, useState } from 'react';
import { IAnsweredOption, IQuestion } from '../store/business/interfaces';
import { ChoiceGroup, CommandButton } from 'office-ui-fabric-react';
import styles from './VotingForm.module.scss';
import { LoadingSpinnerButton } from './atoms/LoadingSpinnerButton';
import { anyInArray } from '../shared/utils';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppState } from '../store';
import { CheckboxWithCounter } from './atoms/CheckboxWithCounter';

export interface Props {
	question: IQuestion;
	onSend: (answeredOptions: IAnsweredOption[]) => void;
	onUnVote: () => void;
	marginContainerName: string;
}

export const VotingForm = (props: Props) => {
	const [answeredOptions, setAnsweredOptions] = useState<IAnsweredOption[]>(props.question.answeredOptions);
	const userVoteWeight = useSelector((state: AppState) => state.business.userVoteWeight);
	const { t } = useTranslation();

	useEffect(() => {
		setAnsweredOptions(() => {
			return props.question.answeredOptions.map((ao) => ({ ...ao }));
		});
	}, [props.question]);

	const onSelectAnswer = (answerId: string, type: 'add' | 'remove' | 'single', amount?: number) => {
		amount = amount ? amount : 1;
		let answers = new Array<IAnsweredOption>();
		let selectedAnswer = { ...props.question.options.find((o) => o.id === answerId), voteWeight: amount };

		switch (type) {
			case 'add':
				answers = answeredOptions.filter((ao) => ao.id !== answerId);
				answers.push(selectedAnswer);
				break;
			case 'remove':
				answers = answeredOptions.filter((ao) => ao.id !== answerId);
				break;
			case 'single':
				answers.push(selectedAnswer);
				break;
		}

		setAnsweredOptions(answers);
	};

	const maxVotes = props.question.votesPerMember * userVoteWeight;
	const remainingVotes =
		maxVotes - answeredOptions.map((ao) => ao.voteWeight).reduce((sum, current) => sum + current, 0);

	return (
		<div className={styles.votingFormContainer}>
			<div className={props.marginContainerName}>
				<div className={styles.votingFormChoice}>
					{props.question.votesPerMember === 1 ? (
						<ChoiceGroup
							key={props.question.votingId}
							disabled={props.question.hasAnswered}
							options={props.question.options.map((a) => {
								return { key: a.id, text: a.title };
							})}
							defaultSelectedKey={props.question.answeredOptions[0]?.id}
							onChange={(evt, opt) => {
								onSelectAnswer(opt?.key, 'single', userVoteWeight);
							}}
						/>
					) : (
						''
					)}
					{props.question.votesPerMember === 0 || props.question.votesPerMember > 1 ? (
						<>
							{props.question.votesPerMember > 1 ? (
								<span className={styles.hintText}>
									{t(`form.remainingVoices`, { remainingVotes: `${remainingVotes}`, maxVotes: `${maxVotes}` })}
								</span>
							) : (
								''
							)}
							{props.question.options.map((o) => (
								<CheckboxWithCounter
									disabled={props.question.hasAnswered}
									key={o.id}
									option={o}
									answer={answeredOptions.find((ao) => ao.id === o.id)}
									remainingVotes={remainingVotes}
									showVoteAmountSetter={userVoteWeight > 1}
									onChange={(answerId, amount) => {
										switch (amount) {
											case 0:
												onSelectAnswer(answerId, 'remove');
												break;
											default:
												onSelectAnswer(answerId, 'add', amount);
												break;
										}
									}}
								/>
							))}
						</>
					) : (
						''
					)}
					{props.question.votesPerMember < 0 ? <>{t('form.questionCannotAnswered')}</> : ''}
					{props.question.hasAnswered && props.question.security === 'private' ? (
						<span className={[styles.hintText, styles.hintSecretVoting].join(' ')}>
							{t('form.questionCannotChanged')}
							{props.question.answeredOptions.length === 0 ? t('form.questionCannotShown') : ''}.
						</span>
					) : (
						''
					)}
				</div>
				<LoadingSpinnerButton
					loadingClassName={styles.loadingButton}
					text={t('form.vote')}
					onClick={() => answeredOptions && props.onSend(answeredOptions)}
					disabled={!answeredOptions || props.question.hasAnswered}
				/>
				{props.question.hasAnswered && props.question.security === 'public' ? (
					<>
						<CommandButton
							text={t('form.changeVote')}
							disabled={!anyInArray(props.question.answeredOptions)}
							onClick={() => {
								props.onUnVote();
							}}
						/>
					</>
				) : (
					<></>
				)}
			</div>
		</div>
	);
};
