import { IBusinessState } from './interfaces';
import { IBusinessActionTypes, DATA_LOADING, SURVEY_LOADED, QUESTION_LOADED, MEMBER_LOADED } from './types';

const initialState: IBusinessState = {
	loading: {
		answer: false,
		question: false,
		survey: false,
	},
};

function innerReducer(state: IBusinessState, action: IBusinessActionTypes): IBusinessState {
	switch (action.type) {
		case DATA_LOADING: {
			return { ...state, loading: { ...state.loading, [action.loading]: action.isLoading } };
		}
		case SURVEY_LOADED: {
			return { ...state, survey: action.survey };
		}
		case QUESTION_LOADED: {
			return { ...state, question: action.question };
		}
		case MEMBER_LOADED: {
			return { ...state, pin: action.pin, code: action.code, userVoteWeight: action.userVoteWeight };
		}
	}
	return state;
}

function businessReducer(state = initialState, action: IBusinessActionTypes): IBusinessState {
	const result = innerReducer(state, action);
	// if (process.env.NODE_ENV !== 'production') {
	// 	console.log(`businessReducer`, action, state, result);
	// }
	return result;
}

export default businessReducer;
