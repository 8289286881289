import { store } from '../store';
import { memberLoaded } from '../store/business/actions';
import { appXhr } from '../xhr';

interface SessionModel {
	pin: string;
	code: string;
	voteWeight: number;
}

export class SessionService {
	public static async createSession(pin: string, code: string, voteWeight: number) {
		await appXhr(`api/v1/Session`, {
			method: 'POST',
			data: { pin, code, voteWeight },
		});
	}

	public static async ensureSession() {
		const session = await this.getSession();
		if (session.pin && session.code && session.voteWeight) {
			store.dispatch(memberLoaded(session.pin, session.code, session.voteWeight));
		}
	}

	public static async getSession(): Promise<SessionModel> {
		const response = await appXhr(`api/v1/Session`, {
			method: 'GET',
		});
		return {
			pin: response.data.pin,
			code: response.data.code,
			voteWeight: response.data.voteWeight
		};
	}

	public static async deleteSession() {
		await appXhr(`api/v1/Session`, {
			method: 'DELETE',
		});
	}
}
