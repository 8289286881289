export enum MessageType {
	INFO = 0,
	WARN = 100,
	ERROR = 200,
	SUCCESS = 300,
}

export enum SystemLoading {
	Config = 'config',
	Login = 'login',
}