import { Loading } from './enums';
import { ISurvey, IQuestion } from './interfaces';

export const DATA_LOADING = 'DATA_LOADING';
export const SURVEY_LOADED = 'SURVEY_LOADED';
export const QUESTION_LOADED = 'QUESTION_LOADED';
export const MEMBER_LOADED = 'MEMBER_LOADED';

// Action Definition
interface IDataLoadingAction {
	type: typeof DATA_LOADING;
	loading: Loading;
	isLoading: boolean;
}

interface ISurveyLoadedAction {
	type: typeof SURVEY_LOADED;
	survey?: ISurvey;
}

interface IQuestionLoadedAction {
	type: typeof QUESTION_LOADED;
	question?: IQuestion;
}

interface IMemberLoadedAction {
	type: typeof MEMBER_LOADED;
	pin?: string;
	code?: string;
	userVoteWeight?: number;
}

// Union Action Types
export type IBusinessActionTypes = IDataLoadingAction | ISurveyLoadedAction | IQuestionLoadedAction | IMemberLoadedAction;
