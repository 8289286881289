import { MessageType, SystemLoading } from './enums';

export const SYSTEM_LOADING = 'SYSTEM_LOADING';
export const MESSAGE_ADD = 'MESSAGE_ADD';
export const MESSAGE_REMOVE = 'MESSAGE_REMOVE';
export const CONFIG_SET = 'CONFIG_SET';
export const CONFIG_LOADED = 'CONFIG_LOADED';

// Action Definition
interface ISystemLoadingAction {
	type: typeof SYSTEM_LOADING;
	loading: SystemLoading;
	isLoading: boolean;
}

interface IMessageAddAction {
	type: typeof MESSAGE_ADD;
	created: Date;
	messageType: MessageType;
	message?: string;
}

interface IMessageRemoveAction {
	type: typeof MESSAGE_REMOVE;
	index: number;
}

interface IConfigLoadedAction {
	type: typeof CONFIG_LOADED;
	title: string | null;
	reversion: number | null;
	userId: string | null;
	apiBasePath?: string;
	apiKey: string | null;
}

// Union Action Types
export type ISystemActionTypes = ISystemLoadingAction | IMessageAddAction | IMessageRemoveAction | IConfigLoadedAction;
