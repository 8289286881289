import styles from './CheckboxWithCounter.module.scss';

import { DefaultButton, Checkbox, IconButton, SpinButton } from 'office-ui-fabric-react';
import React, { useState } from 'react';
import { IAnsweredOption, IAnswerOption } from '../../store/business/interfaces';
import { useTranslation } from 'react-i18next';

export interface Props {
	disabled: boolean;
	option: IAnswerOption;
	answer?: IAnsweredOption;
	showVoteAmountSetter: boolean;
	remainingVotes: number;
	onChange: (answerId: string, amount: number) => void;
}

export const CheckboxWithCounter = (props: Props) => {
	const { t } = useTranslation();
	const [openVoteAmountSetter, setOpenVoteAmountSetter] = useState(false);
	const [numberOfOptionVotes, setNumberOfOptionVotes] = useState<number>(props.answer?.voteWeight || 0);

	const maxNumberOfOptionVotes = props.remainingVotes + (props.answer?.voteWeight || 0);

	const updateOptionVotes = (newNumberOfOptionVotes: number) => {
		if (newNumberOfOptionVotes !== numberOfOptionVotes) {
			if (newNumberOfOptionVotes > maxNumberOfOptionVotes) newNumberOfOptionVotes = maxNumberOfOptionVotes;
			if (newNumberOfOptionVotes < 0) newNumberOfOptionVotes = 0;

			setNumberOfOptionVotes(newNumberOfOptionVotes);
			props.onChange(props.option.id, newNumberOfOptionVotes);
		}
	};

	console.log(props.option.title, numberOfOptionVotes, maxNumberOfOptionVotes);

	return (
		<div className={styles.checkboxWithCounterContainer}>
			{openVoteAmountSetter && !props.disabled ? (
				<>
					<span className={styles.counterText}>{t(`form.voices`)}</span>
					<div className={styles.counterContainer}>
						<IconButton
							iconProps={{ iconName: 'Remove' }}
							disabled={props.disabled || numberOfOptionVotes === 0}
							onClick={() => {
								updateOptionVotes(numberOfOptionVotes - 1);
							}}
							className={styles.counterButton}
						/>
						<SpinButton
							disabled={props.disabled}
							value={'' + numberOfOptionVotes}
							min={0}
							max={maxNumberOfOptionVotes}
							onValidate={(value, event) => {
								const numberValue = parseInt(value);
								if (!isNaN(numberValue)) {
									updateOptionVotes(numberValue);
								}
							}}
							className={styles.counterInput}
						/>
						<IconButton
							iconProps={{ iconName: 'Add' }}
							disabled={props.disabled || numberOfOptionVotes === maxNumberOfOptionVotes}
							onClick={() => {
								updateOptionVotes(numberOfOptionVotes + 1);
							}}
							className={styles.counterButton}
						/>
					</div>
					<IconButton
						disabled={props.disabled}
						iconProps={{ iconName: 'CheckMark' }}
						onClick={() => {
							updateOptionVotes(numberOfOptionVotes);
							setOpenVoteAmountSetter(!openVoteAmountSetter);
						}}
						className={styles.changeVoteCountButton}
					/>
				</>
			) : (
				<>
					<Checkbox
						key={props.option.id}
						label={props.option.title}
						value={props.option.id}
						defaultChecked={numberOfOptionVotes > 0}
						disabled={props.disabled || (numberOfOptionVotes === 0 && maxNumberOfOptionVotes === 0)}
						onChange={(evt, isChecked) => {
							const amount = isChecked ? 1 : 0;
							updateOptionVotes(amount);
							props.onChange(props.option.id, amount);
						}}
						className={styles.answerContainer}
					/>
					{props.showVoteAmountSetter ? (
						<DefaultButton
							disabled={props.disabled || maxNumberOfOptionVotes === 0}
							text={numberOfOptionVotes > 0 ? `- ${numberOfOptionVotes} +` : '0'}
							onClick={() => {
								setOpenVoteAmountSetter(!openVoteAmountSetter);
							}}
							className={[styles.changeVoteCountButton, numberOfOptionVotes === 0 ? styles.noAnswers : ''].join(' ')}
						/>
					) : (
						''
					)}
				</>
			)}
		</div>
	);
};
